import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";

export default function MtbOnderhoud() {
    return (
        <Layout restrictedWidth={false}
                pageTitle={"Jouw MTB voor onderhoud?✓ | Steven's Bike Service"}
                metaDescription={"Jouw MTB voor onderhoud in Deventer? Neem dan contact op met Steven's Bike Service! Dé MTB onderhoud specialist van regio Deventer!✓"}>

            <div className={"fixed top-20 z-0 p-0"}>
                <StaticImage className={"landscape:w-screen portrait:h-screen"}
                             src={"../images/sbs-mtb-werkplaats.jpg"} alt={"Lekker ragguh"}/>
            </div>
            <div className={"h-[65vh]"}></div>

            <div className={"bg-white z-20 w-full"}>
                <div className=" max-w-6xl mx-auto lg:content-between gap-y-6">
                    <h2 className="text-4xl font-extrabold mt-16">Mountainbike onderhoud</h2>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Mountainbiken is een geweldige manier om naar buiten te gaan en te genieten van de natuur en
                        tegelijkertijd wat lichaamsbeweging te krijgen. Om te kunnen genieten van alles wat je
                        mountainbike te bieden heeft, is het belangrijk dat je fiets in topvorm is en blijft.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Door regelmatig onderhoud aan je MTB uit te voeren, kun je dure reparaties in de toekomst
                        vermijden en ervoor zorgen dat je fiets altijd klaar is om over de routes te knallen.
                    </p>
                    <h2 className="text-4xl font-extrabold mt-5">Wat houdt MTB onderhoud precies in?</h2>

                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Dus, wat houdt MTB onderhoud precies in? Bekijk hieronder enkele van de belangrijkste dingen die
                        je makkelijk zelf kan doen om je mountainbike in topvorm te houden:
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Wassen</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Was je MTB regelmatig. Als je dit op de juiste manier doet, voorkom je onnodige slijtage en
                        kun je het meeste uit je mountainbike halen. Door de fiets te wassen en gelijk alles te
                        controleren zul je problemen eerder opmerken en dat kan onnodige en dure reparaties voorkomen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Nou hoef je niet elke keer dat je met je fiets over de routes
                        knalt hem gelijk te wassen maar zeker na een natte en/of modder rit is het goed deze
                        daarna wassen. Dit verwijdert al het vuil dat het frame en/of de onderdelen kan
                        beschadigen. Zand is een uitstekend schuurmiddel en dat is precies wat je niet wil!
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Als je geen tijd hebt voor een volledige wasbeurt, neem dan in elk geval je aandrijving even
                        af en smeer deze met Squirt. Dit helpt voortijdige slijtage te voorkomen. <a
                        href={"/mtb-wassen"}
                        className={"text-blue-600 hover:underline"}
                        target={"_self"}> Lees hier hoe je in acht stappen je bike weer laat shinen</a>.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Ketting</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Zorg dat je aandrijflijn schoon en gesmeerd blijft, en met <a
                        href={"https://www.squirtcyclingproducts.com/nl/squirt-chain-lube-range/"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Squirt</a> wax is dit een makkie. Door het gebruik van deze wax hoef je de
                        ketting nooit meer te ontvetten en trekt deze geen vuil en stof aan.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Een goede vuistregel is om na drie ritten de ketting te smeren. Dit zal de wrijving verminderen
                        en alles soepel laten lopen. Zorg ervoor dat je niet overdrijft - te veel smeermiddel kan juist
                        meer vuil aantrekken. <a
                        href={"/mtb-ketting-onderhoud"}
                        className={"text-blue-600 hover:underline"}
                        target={"_self"}>Lees hier hoe je in drie stappen je ketting smeert</a>.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Remmen</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Je remblokken en schijven schoonmaken en inremmen, zodat deze optimaal blijven remmen, stil
                        zijn en langer mee gaan. Je remmen zijn een van de belangrijkste veiligheidsvoorzieningen
                        van je mountainbike, dus is het belangrijk om ze regelmatig te controleren en ervoor te
                        zorgen dat ze goed werken. Begin met het controleren van de remblokken - als ze te ver
                        versleten zijn, moeten ze vervangen worden.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Ook moet je de remleidingen controleren op
                        knikken of beschadigingen. Als alles er goed uitziet, ga dan verder met het inspecteren van
                        de remschijven of deze versleten zijn of dat er een slag in zit. Als ze te beschadigd of
                        versleten zijn, moeten ze vervangen worden.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Regelmatig rem onderhoud is cruciaal om jezelf
                        veilig te houden op de trails! <a
                        href={"/mtb-remmen-onderhoud"}
                        className={"text-blue-600 hover:underline"}
                        target={"_self"}>Lees hier hoe je jouw remmen in top conditie houd</a>.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Vering</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        De fork, demper en dropper op de juiste manier schoonhouden en smeren om zo onnodige
                        slijtage te
                        voorkomen. <a
                        href={"/mtb-fork-demper-dropper-onderhoud"}
                        className={"text-blue-600 hover:underline"}
                        target={"_self"}>Lees hier hoe je dit doet</a>.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Wielen</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Houd je banden op de juiste druk, je wiellagers, trapaslagers en balhoofdlagers schoon en
                        droog,
                        net zo als de pallen in de body van je achterwiel, zodat het gesmeerd blijft lopen.
                    </p>

                    <h2 className="text-4xl font-extrabold mt-5">Groot mountainbike onderhoud</h2>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Naast het kleine onderhoud wat je zelf regelmatig zult moeten uitvoeren heeft de MTB ook
                        jaarlijks groot onderhoud nodig.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Steven's Bike Service biedt alle soorten van mountainbike
                        onderhoud aan. Of het nu gaat om groot onderhoud, fork onderhoud of alleen het onderhoud van
                        bijvoorbeeld de remmen van jouw bike.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Ik werk met vier soorten onderhoudsbeurten en een bike check.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">APK</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Bij de bike check (apk) controleer ik de mountainbike op 42 punten zodat je helder in beeld
                        krijgt wat de staat van de fiets is, wat er eventueel moet gebeuren en wat de kosten ongeveer
                        zullen zijn.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Junior</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Bij een junior beurt ligt de focus op functioneren en veiligheid. Ik zorg ervoor dat je weer je
                        bike weer top schakelt en je onbezorgd van die drop kunt gaan.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Belofte</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Ga je voor een belofte dan pak ik het stuk preventief onderhoud ook mee in het verhaal, door de
                        wiellagers, balhoofdlagers en trapaslagers schoon te maken, te smeren en te beschermen verleng
                        je de levensduur en voorkom je vervelende tikken en kraken.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Elite</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Met de elite beurt gaat de hele fiets uit elkaar. Alle onderdelen worden in de Muc-off ECO parts
                        washer gewassen en net zoals het frame met Muc-off bike protect behandeld. Dit voorkomt corrosie
                        en zorgt ervoor dat vuil zich minder goed kan hechten waardoor het wassen van je mountainbike
                        een fluitje van een cent wordt! Je fiets is weer als nieuw, of nee beter dan nieuw.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Prof</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Wil je zo min mogelijk tijd besteden aan wassen ga dan voor de prof beurt. Je kostbare fiets
                        wordt dan in tip-top conditie gebracht met de <a
                        href={"https://eu.muc-off.com/products/harsh-condition-barrier-1-400ml"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Muc-Off Harsh Condition Barrier (HCB-1)</a> die wel tot een jaar bescherming
                        biedt. Met een geavanceerde dunne filmlaag maakt hij korte metten met roest en corrosie.
                    </p>

                    {/* Bekijk de onderhoudsbeurten */}

                    <div className={"pb-10 col-span-2"}>
                        <div
                            className="p-4 w-full text-center bg-white rounded-lg border shadow-md sm:p-8 bg-gray-200 text-black">

                            <p className={"px-2 italic col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-5"}>
                                Bekijk de verschillende onderhoudsbeurten en de prijzen.</p>

                            <a href={"/mtb-onderhoudsbeurten"}>
                                <button type="button"
                                        className={"md:w-1/2 text:l lg:text-xl text-black bg-white hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-base px-auto p-5 text-center"}>
                                    Ga naar de onderhoudsbeurten!
                                </button>
                            </a>
                        </div>
                    </div>


                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Als je mountainbike onderhoud nodig heeft, maak dan een afspraak zodat ik je mountainbike goed
                        kan bekijken en zien wat er precies moet gebeuren. Na de controle maak ik een plan van aanpak en
                        geef ik je een schatting van de kosten.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Ook is het mogelijk dat ik tijdens het onderhoud of
                        reparatie je op de hoogte houd via <a
                        href={"/contact?contact=whatsapp"}
                        className={"text-blue-600 hover:underline"}
                        target={"_self"}>WhatsApp</a> zodat je niet voor verrassingen komt te staan bij
                        het ophalen van de fiets. Het blijft immers jouw fiets en zo kan ik afstemmen wat je wel of niet
                        uitgevoerd wil hebben of we kunnen kijken naar alternatieven.
                    </p>
                </div>
            </div>
        </Layout>
    )
}
